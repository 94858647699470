import type { FC } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Form, Modal, Select, Tooltip } from 'antd';
import { useQueryClient } from '@tanstack/react-query';

import type { PermissionEnum } from 'src/api-sdk';
import { useUserGroups } from 'src/hooks/useUserGroups';
import { usePaginatedData } from 'src/hooks/useNormalizePaginatedData';
import { useBinderContext } from 'src/util/context/BinderContext';
import { QUERIES } from 'src/util/globals';
import { usePermissionsContext } from 'src/util/context/PermissionsContext';
import { usePortfolioContext } from 'src/util/context/PortfolioContext';
import PermissionOptionSelect from 'src/components/Permissions/components/PermissionOptionSelect';

import { useAddGroupToBinder } from '../hooks/binders/useAddGroupToBinder';
import { useAddGroupToPortfolio } from '../hooks/portfolios';

type Props = {
  visible: boolean;
  toggleVisibility: (v?: boolean) => void;
};

type Inputs = { groupId: string; permission: PermissionEnum };

export const AddGroupToEntity: FC<Props> = ({ visible, toggleVisibility }) => {
  const { binderData } = useBinderContext();
  const { portfolioId } = usePortfolioContext();
  const client = useQueryClient();
  const { t } = useTranslation('permissions');
  const { permissionsModalState } = usePermissionsContext();
  const [form] = Form.useForm<Inputs>();
  const [selectedGroupId, setSelectedGroupId] = useState<null | string>(null);

  const {
    data: groupsData,
    isLoading: isGroupsLoading,
    isFetching: isGroupsFetching,
  } = useUserGroups({ enabled: visible });
  const { results: groupsList } = usePaginatedData(groupsData);

  const { mutateAsync: addGroupToBinder, isLoading: addGroupToBinderProgress } =
    useAddGroupToBinder({
      onSuccess: () => {
        client.invalidateQueries([QUERIES.BINDER_GROUPS]);
        toggleVisibility();
      },
    });

  const {
    mutateAsync: addGroupToPortfolio,
    isLoading: addGroupPortfolioProgress,
  } = useAddGroupToPortfolio({
    onSuccess: () => {
      client.invalidateQueries([QUERIES.PORTFOLIO_GROUPS]);
      toggleVisibility();
    },
  });

  const handleSubmit = useCallback(async () => {
    const values = await form.validateFields();
    console.log(values);

    if (permissionsModalState?.entity === 'binder') {
      await addGroupToBinder({
        binderId: binderData!.external_id,
        groupId: values.groupId,
        permission: values.permission,
      });
    }

    if (permissionsModalState?.entity === 'portfolio') {
      await addGroupToPortfolio({
        portfolioId: portfolioId!,
        groupId: values.groupId,
        permission: values.permission,
      });
    }
  }, [
    addGroupToBinder,
    addGroupToPortfolio,
    binderData,
    form,
    permissionsModalState?.entity,
    portfolioId,
  ]);

  return (
    <Modal
      visible={visible}
      onCancel={() => toggleVisibility()}
      title={t(`Add group to ${permissionsModalState?.entity}`)}
      okText={t('Save')}
      destroyOnClose
      okButtonProps={{
        loading: addGroupToBinderProgress || addGroupPortfolioProgress,
      }}
      onOk={handleSubmit}
      afterClose={() => {
        form.resetFields();
        setSelectedGroupId(null);
      }}
      width="40%"
      centered
    >
      <Form
        form={form}
        layout="inline"
        className="flex flex-row justify-center"
      >
        <Form.Item
          name="groupId"
          label={t('Select group')}
          className="w-3/6"
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t('Select group')}
            className="mb-3"
            onChange={(value: string) => {
              setSelectedGroupId(value ?? null);
            }}
            allowClear
            showSearch
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            loading={isGroupsLoading || isGroupsFetching}
          >
            {groupsList.map(({ name, external_id }) => (
              <Select.Option key={external_id} value={external_id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="permission"
          label={t('Permission')}
          className="w-3/6"
          rules={[{ required: true }]}
        >
          <PermissionOptionSelect
            disabled={!selectedGroupId}
            entity={permissionsModalState?.entity || ''}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
