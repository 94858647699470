import type { FC } from 'react';
import { createElement } from 'react';

export const composeWrappers = (wrappers: FC[]): FC =>
  wrappers.reduceRight(
    (Acc, Current): FC =>
      // eslint-disable-next-line func-names, react/display-name
      function (props) {
        return createElement(Current, null, createElement(Acc, props as never));
      }
  );
