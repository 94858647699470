import React, { useContext } from 'react';

interface LayoutContextInterface {
  collapsedMenu: boolean;
  setCollapsedMenu: (menuVisible: boolean) => void;
}

const layoutContext = React.createContext<LayoutContextInterface>(
  {} as ReturnType<typeof useProvideLayout>
);

// Provider component that wraps your app and makes axios object ..
// ... available to any child component that calls useAxios().

export function ProvideLayout({ children }: any): any {
  const auth = useProvideLayout();
  return (
    <layoutContext.Provider value={auth}>{children}</layoutContext.Provider>
  );
}

// Hook for child components to get the axios object ...
// ... and re-render when it changes.

export const useLayout = () => {
  return useContext(layoutContext);
};

// Provider hook that creates auth object and handles state
function useProvideLayout(): LayoutContextInterface {
  const [collapsedMenu, setCollapsedMenu] = React.useState(false);

  return {
    collapsedMenu,
    setCollapsedMenu,
  };
}
