import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { Form, Modal, notification, Select } from 'antd';
import _ from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import { useDebouncedState } from '@react-hookz/web';

import { useBinderContext } from 'src/util/context/BinderContext';
import {
  useBinderItem,
  useBinderItemTagsUpdate,
  useBinderTags,
} from 'src/hooks/useBinders';
import { usePaginatedData } from 'src/hooks/useNormalizePaginatedData';
import { QUERIES } from 'src/util/globals';

type FormValues = {
  tags: string[];
};

export const AddBinderItemTagsModal: FC = () => {
  const [searchQuery, setSearchQuery] = useDebouncedState<string | undefined>(
    undefined,
    300
  );
  const { t } = useTranslation('binder');
  const {
    binderItemTagsModalVisible,
    toggleBinderItemTagsModalVisible,
    binderId,
    binderItemId,
  } = useBinderContext();
  const [form] = Form.useForm<FormValues>();
  const client = useQueryClient();

  const { data: itemData } = useBinderItem(
    {
      binderId: binderId!,
      binderItemId: binderItemId!,
    },
    {
      enabled: binderItemTagsModalVisible,
    }
  );
  const {
    data: binderTags,
    isLoading: tagsLoading,
    isFetching: tagsFetching,
  } = useBinderTags({
    binderId: binderId!,
    enabled: binderItemTagsModalVisible,
    query: searchQuery,
  });
  const { results: binderTagsData } = usePaginatedData(binderTags);

  const { mutateAsync: updateItemTags, isLoading } = useBinderItemTagsUpdate(
    {
      binderId: binderId!,
      binderItemId: binderItemId!,
    },
    {
      onSuccess: () => {
        toggleBinderItemTagsModalVisible();
        notification.success({
          message: t('Tag updated successfully!'),
        });
        client.invalidateQueries([QUERIES.BINDER_DATA]);
        client.invalidateQueries([QUERIES.BINDER_TAGS]);
      },
    }
  );

  const tagsOptions = useMemo(() => {
    return _.differenceBy(binderTagsData, itemData?.tags ?? [], 'text');
  }, [binderTagsData, itemData?.tags]);

  const handleSubmit = useCallback(async () => {
    const { tags } = form.getFieldsValue();

    await updateItemTags([
      ...tags,
      ...(itemData?.tags.map((t) => t.text) ?? []),
    ]);
  }, [form, updateItemTags, itemData?.tags]);

  return (
    <Modal
      visible={binderItemTagsModalVisible}
      onCancel={() => toggleBinderItemTagsModalVisible()}
      destroyOnClose
      title={t(`Add tags`)}
      afterClose={() => form.resetFields()}
      onOk={handleSubmit}
      okText={t('Add tags')}
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <Form form={form} layout="vertical" autoComplete="off">
        <Form.Item name="tags" label={t('Select tags')}>
          <Select
            showSearch
            onSearch={setSearchQuery}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            mode="tags"
            allowClear
            placeholder={t('Enter tags')}
            loading={tagsLoading || tagsFetching}
          >
            {tagsOptions.map((tag) => (
              <Select.Option key={tag.external_id} value={tag.text}>
                {tag.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
