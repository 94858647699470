import { Select, Tooltip } from 'antd';
import { useTranslation } from 'next-i18next';

import { PermissionEnum } from 'src/api-sdk';

const PermissionOptionSelect = ({
  value,
  entity,
  onChange,
  disabled,
}: {
  value?: string;
  entity: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation('permissions');

  return (
    <Select
      onChange={(value, option) => {
        if (onChange) {
          onChange(value);
        }
      }}
      value={value}
      disabled={disabled}
    >
      <Select.Option
        key={PermissionEnum.ViewOnly}
        value={PermissionEnum.ViewOnly}
      >
        <Tooltip
          title={
            entity === 'portfolio'
              ? t('Only access to use the portfolio')
              : t('Only access to read the binder')
          }
        >
          {t('View only')}
        </Tooltip>
      </Select.Option>
      <Select.Option key={PermissionEnum.Write} value={PermissionEnum.Write}>
        <Tooltip
          title={
            entity === 'portfolio'
              ? t('Access to use the portfolio and add new companies')
              : t('Access to use the binder and add new articles')
          }
        >
          {t('Write')}
        </Tooltip>
      </Select.Option>
      <Select.Option key={PermissionEnum.Admin} value={PermissionEnum.Admin}>
        <Tooltip
          title={
            entity === 'portfolio'
              ? t('Use, edit and administrate the portfolio')
              : t('Use, edit and administrate the binder')
          }
        >
          {t('Admin')}
        </Tooltip>
      </Select.Option>
      <Select.Option key={PermissionEnum.Owner} value={PermissionEnum.Owner}>
        <Tooltip
          title={
            entity === 'portfolio'
              ? t('Owner of the portfolio')
              : t('Owner of the binder')
          }
        >
          {t('Owner')}
        </Tooltip>
      </Select.Option>
    </Select>
  );
};

export default PermissionOptionSelect;
