import { useMutation } from '@tanstack/react-query';

import type { MutationHookOptions } from 'src/types/global';
import { useAPIContext } from 'src/util/context/APIContext';

type RemoveGroupFromBinderParams = {
  binderId: string;
  groupId: string;
};

export const useRemoveGroupFromBinder = (
  options: MutationHookOptions<void, Error, RemoveGroupFromBinderParams>
) => {
  const { API } = useAPIContext();

  return useMutation(
    async ({ binderId, groupId }: RemoveGroupFromBinderParams) => {
      return (
        await API?.bindersAPI.bindersProfileUserGroupsDestroy({
          binderId,
          profileUserGroupId: groupId,
        })
      )?.data;
    },
    { ...options }
  );
};
