import { Button } from 'antd';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { Dispatch, FC, MouseEventHandler, SetStateAction } from 'react';

import type {
  PaginatedBinderList,
  PaginatedPortfolioList,
  PaginatedUniversalCompanyList,
} from 'src/api-sdk';

type Props = {
  companiesData: PaginatedUniversalCompanyList | undefined;
  portfolioData: PaginatedPortfolioList | undefined;
  binderData: PaginatedBinderList | undefined;
  searchQuery?: string;
  setIsSelectOpen: Dispatch<SetStateAction<boolean>>;
  setOnDropdown: Dispatch<SetStateAction<boolean>>;
};

export const RenderTopBar: FC<Props> = ({
  companiesData,
  portfolioData,
  binderData,
  searchQuery,
  setIsSelectOpen,
  setOnDropdown,
}: Props) => {
  const router = useRouter();
  const { t } = useTranslation('account');

  return (
    <div
      onMouseEnter={() => setOnDropdown(true)}
      onMouseLeave={() => {
        setOnDropdown(false);
      }}
    >
      {/* Display companies Data in autocomplete popup */}
      {companiesData?.results?.length !== 0 ? (
        <h5 className="pl-[10px]  text-xs pt-[1px] text-gray-400 flex justify-between">
          <span className="p-[8px]">{t('Companies')}</span>
          <Button
            className="p-0"
            // tailwind css is being overridden by antd css
            style={{
              marginBottom: 0,
            }}
            type="link"
            disabled={!companiesData ? true : false}
            onMouseDown={() => {
              router.push(`/companies?q=${searchQuery}`);
              setIsSelectOpen(false);
            }}
          >
            {t('more')}
          </Button>
        </h5>
      ) : null}
      {companiesData?.results?.slice(0, 3).flatMap((item) => (
        <p key={item.name} className="pl-[30px] text-gray-900">
          <a
            href={
              item.external_id
                ? `/companies/${item.external_id}`
                : `/companies/registration?duns_number=${item.duns_number}`
            }
          >
            {t(item.name.slice(0, 25))}
          </a>
        </p>
      ))}
      {/* Display Portfolios Data in autocomplete popup */}
      {portfolioData?.results?.length !== 0 ? (
        <h5 className="pl-[10px] text-xs pt-[1px] text-gray-400 flex justify-between">
          <span className="p-[8px]">{t('Portfolios')}</span>
          <Button
            className="mb-0 p-0"
            // tailwind css is being overridden by antd css
            style={{
              marginBottom: 0,
            }}
            type="link"
            disabled={!portfolioData}
            onMouseDown={() => {
              router.push(`/portfolios?q=${searchQuery}`);
              setIsSelectOpen(false);
            }}
          >
            {t('more')}
          </Button>
        </h5>
      ) : null}
      {portfolioData?.results?.slice(0, 3).flatMap((item) => (
        <p
          key={item.external_id}
          className="pl-[30px] text-gray-900 cursor-pointer justify-between"
        >
          <a href={`/portfolios/${item.external_id}/companies`}>
            {t(item.name)}
          </a>
        </p>
      ))}
      {/* Display Binder Data in autocomplete popup */}
      {binderData?.results?.length !== 0 ? (
        <h5 className="pl-[10px] pt-[1px]  text-xs text-gray-400 flex justify-between">
          <span className="p-[8px]">{t('Binders')}</span>
          <Button
            // tailwind css is being overridden by antd css
            style={{
              marginBottom: 0,
            }}
            className="mb-0 p-0"
            disabled={!binderData ? true : false}
            type="link"
            onMouseDown={() => {
              router.push(`/binders?q=${searchQuery}`);
              setIsSelectOpen(false);
            }}
          >
            {t('more')}
          </Button>
        </h5>
      ) : null}
      {binderData?.results?.slice(0, 3).flatMap((item) => (
        <p
          key={item.external_id}
          className="pl-[30px] text-gray-900 justify-between"
        >
          <a href={`/binders/${item.external_id}`}>{item.name}</a>
        </p>
      ))}
      <Button
        className="my-[6px] mx-[18px] w-[90%]"
        disabled={
          !companiesData?.total_results &&
          !portfolioData?.total_results &&
          !binderData?.total_results
            ? true
            : false
        }
        onMouseDown={() => {
          setIsSelectOpen(false);
          router.push(`/search-results?q=${searchQuery}`);
        }}
      >
        {t('See all results')}
      </Button>
    </div>
  );
};
