import { useMutation } from '@tanstack/react-query';

import type { BinderProfileUserGroup, PermissionEnum } from 'src/api-sdk';
import type { MutationHookOptions } from 'src/types/global';
import { useAPIContext } from 'src/util/context/APIContext';

type AddGroupToBinderParams = {
  binderId: string;
  groupId: string;
  permission: PermissionEnum;
};

export const useAddGroupToBinder = (
  options: MutationHookOptions<
    BinderProfileUserGroup | undefined,
    Error,
    AddGroupToBinderParams
  > = {}
) => {
  const { API } = useAPIContext();

  return useMutation(
    async ({ binderId, groupId, permission }: AddGroupToBinderParams) => {
      return (
        await API?.bindersAPI.bindersProfileUserGroupsCreate({
          binderId,
          binderProfileUserGroupRequest: {
            profile_user_group: {
              external_id: groupId,
            },
            permission: permission,
          },
        })
      )?.data;
    },
    { ...options }
  );
};
