import { useInfiniteQuery } from '@tanstack/react-query';

import { useAPIContext } from 'src/util/context/APIContext';
import { QUERIES } from 'src/util/globals';

export const useUserGroups = ({ enabled = false }: { enabled?: boolean }) => {
  const { API } = useAPIContext();

  return useInfiniteQuery(
    [QUERIES.GROUPS],
    async ({ pageParam }) => {
      return (
        await API?.profilesAPI.profilesProfileUserGroupsList({
          nextKey: pageParam || undefined,
        })
      )?.data;
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage?.next_key || undefined,
      enabled,
    }
  );
};
