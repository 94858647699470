import React from 'react';
import { Spin } from 'antd';
import Lottie from 'lottie-react';
import clsx from 'clsx';

import loadingIcon from 'loadingIcon.json';

interface LoaderProps {
  loading: boolean;
  children: React.ReactNode;
}

/**
 * Spinner for loading over a child element without replacing the whole page content
 * @param loading: Boolean indicating if loading
 * @param children: Children elements
 * @constructor
 */
export const Loader = ({ loading, children }: LoaderProps) => {
  return (
    <Spin
      spinning={loading}
      indicator={
        <Lottie
          animationData={loadingIcon}
          style={{
            width: '60px',
            height: '60px',
          }}
        />
      }
    >
      {children}
    </Spin>
  );
};

/**
 * Full Screen Loader, replace whole page content and displays loader.
 * @constructor
 */
const FullScreenLoader = ({
  isMain,
  className,
  children,
}: {
  isMain?: boolean;
  className?: string;
  children?: React.ReactNode;
}) => (
  <>
    <div
      className={clsx(
        'flex justify-center h-full w-full',
        {
          absolute: isMain,
        },
        className
      )}
    >
      {children}
      <Lottie
        animationData={loadingIcon}
        style={{ width: '100px', height: '100px' }}
      />
    </div>
  </>
);
export default FullScreenLoader;
