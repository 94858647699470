import type { FC } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import type { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'next-i18next';
import { useToggle } from '@react-hookz/web';
import { Button, Modal, Popover, Tooltip } from 'antd';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';

import { usePaginatedData } from 'src/hooks/useNormalizePaginatedData';
import { useBinderContext } from 'src/util/context/BinderContext';
import { SmartTable } from 'src/components/SmartTable';
import type { BinderUser, PortfolioUser } from 'src/api-sdk';
import { usePermissionsContext } from 'src/util/context/PermissionsContext';
import { QUERIES } from 'src/util/globals';
import { usePortfolioContext } from 'src/util/context/PortfolioContext';

import { UpdateUserPermissions } from './UpdateUserPermissions';
import { useBinderUsers, useRemoveUserFromBinder } from '../hooks/binders';
import { AddUserToEntity } from './AddUserToEntity';
import {
  usePortfolioUsers,
  useRemoveUserFromPortfolio,
} from '../hooks/portfolios';

type TableData = {
  users: PortfolioUser[] | PortfolioUser[] | undefined;
  hasMore: boolean | undefined;
  isLoading: boolean | undefined;
  isFetching: boolean | undefined;
  fetchNext: () => void;
} | null;

export const PermissionsUsersList: FC = () => {
  const { t } = useTranslation('permissions');
  const { permissionsModalState } = usePermissionsContext();
  const { portfolioId } = usePortfolioContext();
  const client = useQueryClient();
  const [addNewUserVisible, toggleNewUserVisible] = useToggle(false);
  const { binderData } = useBinderContext();

  const {
    data: binderUsersData,
    isLoading: isBinderUsersLoading,
    hasNextPage: binderUsersHasMore,
    fetchNextPage: binderUsersFetchNext,
    isFetching: isBinderUsersFetching,
  } = useBinderUsers(
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      binderId: binderData?.external_id!,
      searchQuery: '',
    },
    {
      enabled: Boolean(
        permissionsModalState?.visible &&
          permissionsModalState.entity === 'binder'
      ),
      cacheTime: 0,
    }
  );
  const {
    data: portfolioUsersData,
    isLoading: isPortfolioUsersDataLoading,
    hasNextPage: portfolioUsersHasMore,
    fetchNextPage: portfolioUsersFetchNext,
    isFetching: isPortfolioUsersFetching,
  } = usePortfolioUsers(
    {
      portfolioId: portfolioId!,
      searchQuery: '',
    },
    {
      enabled: Boolean(
        permissionsModalState?.visible &&
          permissionsModalState.entity === 'portfolio'
      ),
      cacheTime: 0,
    }
  );

  const { results: binderUsers } = usePaginatedData(binderUsersData);
  const { results: portfolioUsers } = usePaginatedData(portfolioUsersData);

  const { mutateAsync: removeUserFromBinder, isLoading: userRemovalProgress } =
    useRemoveUserFromBinder({
      onSuccess: () => {
        client.invalidateQueries([QUERIES.BINDER_USERS]);
        client.invalidateQueries([QUERIES.BINDERS]);
      },
    });

  const {
    mutateAsync: removeUserFromPortfolio,
    isLoading: userRemovalProgressP,
  } = useRemoveUserFromPortfolio({
    onSuccess: () => {
      client.invalidateQueries([QUERIES.PORTFOLIO_USERS]);
      client.invalidateQueries([QUERIES.PORTFOLIOS]);
    },
  });

  const handleRemoveGroupFromEntity = (userId: string) => {
    Modal.confirm({
      title: t(`Remove user from ${permissionsModalState?.entity}?`),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        if (permissionsModalState?.entity === 'binder') {
          await removeUserFromBinder({
            userId,
            binderId: binderData!.external_id,
          });
        }

        if (permissionsModalState?.entity === 'portfolio') {
          await removeUserFromPortfolio({
            userId,
            portfolioId: portfolioId!,
          });
        }
      },
      okText: t('Confirm'),
      cancelText: t('Cancel'),
      okButtonProps: {
        loading: userRemovalProgress,
      },
    });
  };

  const tableData = useMemo<TableData>(() => {
    if (permissionsModalState?.entity === 'binder') {
      return {
        users: binderUsers,
        hasMore: binderUsersHasMore,
        fetchNext: binderUsersFetchNext,
        isLoading: isBinderUsersLoading,
        isFetching: isBinderUsersFetching,
      };
    } else if (permissionsModalState?.entity === 'portfolio') {
      return {
        users: portfolioUsers,
        hasMore: portfolioUsersHasMore,
        fetchNext: portfolioUsersFetchNext,
        isLoading: isPortfolioUsersDataLoading,
        isFetching: isPortfolioUsersFetching,
      };
    }

    return null;
  }, [
    binderUsers,
    binderUsersFetchNext,
    binderUsersHasMore,
    isBinderUsersFetching,
    isBinderUsersLoading,
    isPortfolioUsersDataLoading,
    isPortfolioUsersFetching,
    permissionsModalState?.entity,
    portfolioUsers,
    portfolioUsersFetchNext,
    portfolioUsersHasMore,
  ]);

  const columns: ColumnsType<BinderUser | PortfolioUser> = [
    {
      title: t('Name'),
      dataIndex: ['user', 'username'],
    },
    {
      title: t('Permission'),
      dataIndex: 'permission',
      render: (text) => {
        return <span>{t(text.charAt(0).toUpperCase() + text.slice(1))}</span>;
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="flex justify-start items-start">
            <Tooltip title={t('Change permission')}>
              <Popover
                title={t('Select new permission')}
                trigger="click"
                placement="bottom"
                destroyTooltipOnHide
                content={
                  <UpdateUserPermissions
                    currentPermission={record.permission!}
                    userId={record.external_id}
                  />
                }
              >
                <Button
                  icon={<LockOutlined />}
                  className="!mb-0"
                  type="dashed"
                />
              </Popover>
            </Tooltip>
            <Tooltip title={t(`Remove from ${permissionsModalState?.entity}`)}>
              <Button
                icon={<DeleteOutlined />}
                className="!mb-0"
                danger
                onClick={() => handleRemoveGroupFromEntity(record.external_id)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="flex flex-row justify-end">
        <Button onClick={() => toggleNewUserVisible()}>
          {t('Select user')}
        </Button>
      </div>
      <SmartTable
        infinity
        dataSource={tableData?.users ?? []}
        columns={columns}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        fetchNext={tableData?.fetchNext ?? (() => {})}
        hasNext={tableData?.hasMore ?? false}
        loading={(tableData?.isLoading || tableData?.isFetching) ?? false}
      />
      <AddUserToEntity
        visible={addNewUserVisible}
        toggleVisibility={toggleNewUserVisible}
      />
    </div>
  );
};
