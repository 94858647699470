import React, { createContext, useState, useContext, useMemo } from 'react';

import { PermissionsManagementModal } from 'src/components/Permissions';

export type PermissionEntityTarget = 'binder' | 'portfolio';

type PermissionModalState = {
  visible: boolean;
  entity: PermissionEntityTarget | null;
};

type PermissionsContextType = {
  permissionsModalState: PermissionModalState | null;
  setPermissionsModalState: (state?: PermissionModalState) => void;
};

export const PermissionsContext = createContext<PermissionsContextType | null>(
  null
);

export const usePermissionsContext = (): PermissionsContextType => {
  const permissionsContext = useContext(PermissionsContext);

  if (!permissionsContext) {
    throw new Error(
      'permissionsContext must be used within a PermissionsContextProvider'
    );
  }

  return permissionsContext;
};

export const PermissionsContextProvider: React.FC = ({ children }) => {
  const [permissionsModalState, setPermissionsModalState] =
    useState<PermissionModalState | null>(null);

  const value = useMemo(() => {
    return {
      permissionsModalState,
      setPermissionsModalState: (state?: PermissionModalState) => {
        setPermissionsModalState(state ?? null);
      },
    };
  }, [permissionsModalState, setPermissionsModalState]);

  return (
    <PermissionsContext.Provider value={value}>
      {children}
      <PermissionsManagementModal />
    </PermissionsContext.Provider>
  );
};
