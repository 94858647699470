import { Button, Form, notification, Select } from 'antd';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import type { PermissionEnum } from 'src/api-sdk';
import { useBinderContext } from 'src/util/context/BinderContext';
import { QUERIES } from 'src/util/globals';
import { usePermissionsContext } from 'src/util/context/PermissionsContext';
import { usePortfolioContext } from 'src/util/context/PortfolioContext';
import PermissionOptionSelect from 'src/components/Permissions/components/PermissionOptionSelect';

import { useUpdateUserPermissionForBinder } from '../hooks/binders';
import { useUpdateUserPermissionForPortfolio } from '../hooks/portfolios';

type Props = {
  userId: string | null;
  currentPermission: PermissionEnum;
};

type Inputs = {
  permission: PermissionEnum;
};

export const UpdateUserPermissions: FC<Props> = ({
  userId,
  currentPermission,
}) => {
  const { t } = useTranslation('permissions');
  const { permissionsModalState } = usePermissionsContext();
  const { portfolioId } = usePortfolioContext();
  const { binderData } = useBinderContext();
  const [form] = Form.useForm<Inputs>();
  const client = useQueryClient();
  const { mutateAsync: updateUserPermissionB, isLoading: updateInProgressB } =
    useUpdateUserPermissionForBinder({
      onSuccess: () => {
        client.invalidateQueries([QUERIES.BINDER_USERS]);
        client.invalidateQueries([QUERIES.BINDERS]);
        notification.success({
          message: t('Permissions updated successfully!'),
        });
      },
    });
  const { mutateAsync: updateUserPermissionP, isLoading: updateInProgressP } =
    useUpdateUserPermissionForPortfolio({
      onSuccess: () => {
        client.invalidateQueries([QUERIES.PORTFOLIO_USERS]);
        client.invalidateQueries([QUERIES.PORTFOLIOS]);
        notification.success({
          message: t('Permissions updated successfully!'),
        });
      },
    });

  const updateInProgress = updateInProgressB || updateInProgressP;

  const onFinish = useCallback(async () => {
    const values = await form.validateFields();

    if (permissionsModalState?.entity === 'binder') {
      await updateUserPermissionB({
        binderId: binderData!.external_id,
        userId: userId!,
        permission: values.permission,
      });
    }

    if (permissionsModalState?.entity === 'portfolio') {
      await updateUserPermissionP({
        portfolioId: portfolioId!,
        userId: userId!,
        permission: values.permission,
      });
    }
  }, [
    form,
    permissionsModalState?.entity,
    updateUserPermissionB,
    binderData,
    userId,
    updateUserPermissionP,
    portfolioId,
  ]);

  return (
    <Form
      form={form}
      initialValues={{
        permission: currentPermission,
      }}
      layout="vertical"
      className="flex flex-col"
      onFinish={onFinish}
    >
      <Form.Item name="permission" label={t('Permission')} className="!mb-3">
        <PermissionOptionSelect entity={permissionsModalState?.entity || ''} />
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={updateInProgress}>
        {t('Update')}
      </Button>
    </Form>
  );
};
