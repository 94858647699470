import { useMutation } from '@tanstack/react-query';

import type { PermissionEnum, PortfolioProfileUserGroup } from 'src/api-sdk';
import type { MutationHookOptions } from 'src/types/global';
import { useAPIContext } from 'src/util/context/APIContext';

type UseUpdateGroupPermissionForPortfolioParams = {
  portfolioId: string;
  groupId: string;
};

type Inputs = {
  permission: PermissionEnum;
};

export const useUpdateGroupPermissionForPortfolio = (
  { portfolioId, groupId }: UseUpdateGroupPermissionForPortfolioParams,
  options: MutationHookOptions<
    PortfolioProfileUserGroup | undefined,
    Error,
    Inputs
  > = {}
) => {
  const { API } = useAPIContext();

  return useMutation(
    async ({ permission }: Inputs) => {
      return (
        await API?.portfoliosAPI.portfoliosProfileUserGroupsPartialUpdate({
          portfolioId,
          profileUserGroupId: groupId,
          patchedPortfolioProfileUserGroupRequest: {
            permission,
          },
        })
      )?.data;
    },
    {
      ...options,
    }
  );
};
