import { useMutation } from '@tanstack/react-query';

import type { PermissionEnum } from 'src/api-sdk';
import type { MutationHookOptions } from 'src/types/global';
import { useAPIContext } from 'src/util/context/APIContext';

type UseUpdateGroupPermissionForBinderParams = {
  binderId: string;
  groupId: string;
};

type Inputs = {
  permission: PermissionEnum;
};

export const useUpdateGroupPermissionForBinder = (
  { binderId, groupId }: UseUpdateGroupPermissionForBinderParams,
  options: MutationHookOptions = {}
) => {
  const { API } = useAPIContext();

  return useMutation(
    async ({ permission }: Inputs) => {
      return (
        await API?.bindersAPI.bindersProfileUserGroupsPartialUpdate({
          binderId,
          profileUserGroupId: groupId,
          patchedBinderProfileUserGroupRequest: {
            permission,
          },
        })
      )?.data;
    },
    {
      ...options,
    }
  );
};
