import type { FC } from 'react';
import { Modal, Tabs } from 'antd';
import { useTranslation } from 'next-i18next';

import { usePermissionsContext } from 'src/util/context/PermissionsContext';

import { PermissionsGroupsList } from './components/PermissionsGroupsList';
import { PermissionsUsersList } from './components/PermissionsUsersList';

export const PermissionsManagementModal: FC = () => {
  const { permissionsModalState, setPermissionsModalState } =
    usePermissionsContext();
  const { t } = useTranslation('permissions');

  return (
    <Modal
      visible={permissionsModalState?.visible ?? false}
      onCancel={() => setPermissionsModalState()}
      onOk={() => setPermissionsModalState()}
      destroyOnClose
      okText={t('Done')}
      width="50%"
      title={t(`Manage permissions for ${permissionsModalState?.entity}`)}
    >
      <Tabs>
        <Tabs.TabPane tab={t('Groups')} key="permission-groups">
          <PermissionsGroupsList />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('Users')} key="permission-users">
          <PermissionsUsersList />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};
