import { useMutation } from '@tanstack/react-query';

import type { MutationHookOptions } from 'src/types/global';
import { useAPIContext } from 'src/util/context/APIContext';

type RemoveUserFromBinderParams = {
  binderId: string;
  userId: string;
};

export const useRemoveUserFromBinder = (
  options: MutationHookOptions<void, Error, RemoveUserFromBinderParams> = {}
) => {
  const { API } = useAPIContext();

  return useMutation(
    async ({ binderId, userId }: RemoveUserFromBinderParams) => {
      return (
        await API?.bindersAPI.bindersUsersDestroy({
          binderId,
          binderUserId: userId,
        })
      )?.data;
    },
    { ...options }
  );
};
