import React, { createContext, useState, useContext, useMemo } from 'react';

type PortfolioContextType = {
  portfolioId: string | null;
  setPortfolioId: (v: string | null) => void;
};

export const PortfolioContext = createContext<PortfolioContextType | null>(
  null
);

export const usePortfolioContext = (): PortfolioContextType => {
  const portfolioContext = useContext(PortfolioContext);

  if (!portfolioContext) {
    throw new Error(
      'portfolioContext must be used within a PortfolioContextProvider'
    );
  }

  return portfolioContext;
};

export const PortfolioContextProvider: React.FC = ({ children }) => {
  const [portfolioId, setPortfolioId] = useState<string | null>(null);

  const value = useMemo(
    () => ({
      portfolioId,
      setPortfolioId,
    }),
    [portfolioId, setPortfolioId]
  );

  return (
    <PortfolioContext.Provider value={value}>
      {children}
    </PortfolioContext.Provider>
  );
};
