import type { FC } from 'react';
import { useCallback } from 'react';
import { Form, Input, Modal, notification } from 'antd';
import { useTranslation } from 'next-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { useUpdateBinder } from 'src/hooks/useBinders';
import { useBinderContext } from 'src/util/context/BinderContext';
import { QUERIES } from 'src/util/globals';

type FormValues = {
  name: string;
};

export const EditBinder: FC = () => {
  const { t } = useTranslation('binders');
  const [form] = Form.useForm<FormValues>();
  const {
    editBinderModalVisible,
    binderData,
    toggleEditBinderModalVisibility,
    setBinderData,
  } = useBinderContext();
  const client = useQueryClient();
  const { mutateAsync: removeBinder, isLoading: binderRemoveProgress } =
    useUpdateBinder({
      onSuccess: () => {
        client.invalidateQueries([QUERIES.BINDERS]);
        client.invalidateQueries([QUERIES.BINDER_DATA]);
        setBinderData(null);
        toggleEditBinderModalVisibility();
        notification.success({
          message: t('Binder has been updated'),
        });
      },
    });

  const handleSubmit = useCallback(async () => {
    const values = await form.validateFields();

    await removeBinder({
      binderId: binderData!.external_id,
      name: values.name,
    });
  }, [form, removeBinder, binderData]);

  return (
    <Modal
      visible={editBinderModalVisible}
      onCancel={() => toggleEditBinderModalVisibility()}
      destroyOnClose
      onOk={handleSubmit}
      okButtonProps={{ loading: binderRemoveProgress }}
      okText={t('Update')}
      title={t(`Edit binder`)}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ name: binderData?.name }}
      >
        <Form.Item
          label={t('Name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input size="large" placeholder="Name" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
