import type { FC } from 'react';
import React from 'react';

type Props = {
  title: string;
  description?: any;
};

export const SideBar: FC<Props> = ({ children, title, description }) => {
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container" style={{ maxWidth: 850 }}>
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div
              className="gx-app-logo-content-bg"
              style={{ backgroundColor: '#01689B' }}
            />
            <div className="gx-app-logo-wid">
              <h1 data-cy="sidebar-title">{title}</h1>
              {description ? <div>{description}</div> : null}
            </div>
          </div>
          <div className="gx-app-login-content">{children}</div>
        </div>
      </div>
    </div>
  );
};
