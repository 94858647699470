// @ts-ignore
import ReduxLazyScroll from 'redux-lazy-scroll';
import { Alert } from 'antd';
import React from 'react';

import type { ErrorDescription } from 'src/api-sdk';

const InfiniteScroll = ({
  loadMore,
  hasMore,
  error,
  isLoading,
  children,
}: {
  loadMore: () => any;
  hasMore?: boolean;
  error?: any;
  isLoading?: boolean;
  children?: any;
}) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.status_code
  ) {
    const errorDetail: ErrorDescription = error.response.data;
    return (
      <Alert
        type="error"
        showIcon
        message={errorDetail.title}
        description={errorDetail.description}
      />
    );
  } else if (error && error.response && error.response.statusText) {
    console.log(error.response);
    return <Alert type="error" showIcon message={error.response.statusText} />;
  } else if (error) {
    throw error;
  }

  return (
    <ReduxLazyScroll
      loadMore={loadMore}
      hasMore={hasMore}
      isFetching={isLoading}
    >
      {children}
    </ReduxLazyScroll>
  );
};
export default InfiniteScroll;
