import { useMutation } from '@tanstack/react-query';

import type { PortfolioProfileUserGroup, PermissionEnum } from 'src/api-sdk';
import type { MutationHookOptions } from 'src/types/global';
import { useAPIContext } from 'src/util/context/APIContext';

type AddGroupToPortfolioParams = {
  portfolioId: string;
  groupId: string;
  permission: PermissionEnum;
};

export const useAddGroupToPortfolio = (
  options: MutationHookOptions<
    PortfolioProfileUserGroup | undefined,
    Error,
    AddGroupToPortfolioParams
  > = {}
) => {
  const { API } = useAPIContext();

  return useMutation(
    async ({ portfolioId, groupId, permission }: AddGroupToPortfolioParams) => {
      return (
        await API?.portfoliosAPI.portfoliosProfileUserGroupsCreate({
          portfolioId,
          portfolioProfileUserGroupRequest: {
            profile_user_group: {
              external_id: groupId,
            },
            permission: permission,
          },
        })
      )?.data;
    },
    { ...options }
  );
};
