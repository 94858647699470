import { useMemo } from 'react';
import _ from 'lodash';
import type { InfiniteData } from '@tanstack/react-query';

type PaginatedData<T = null> = {
  next_key?: string | null;
  total_results?: number | null;
  results?: T[] | undefined;
};

export const usePaginatedData = <T = null>(
  data?: InfiniteData<PaginatedData<T | undefined> | undefined>
) => {
  const results = useMemo(
    () =>
      (_.flatten(
        data?.pages?.map((page) => page?.results).filter((group) => group)
      ) ?? []) as T[],
    [data]
  );
  const totalResults = useMemo(
    () => data?.pages[0]?.total_results ?? 0,
    [data?.pages]
  );

  return {
    results,
    totalResults,
  };
};
