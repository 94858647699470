import { Modal, notification, Typography } from 'antd';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useRemoveBinder } from 'src/hooks/useBinders';
import { useBinderContext } from 'src/util/context/BinderContext';
import { QUERIES } from 'src/util/globals';

export const RemoveBinder: FC = () => {
  const client = useQueryClient();
  const { t } = useTranslation('binders');
  const {
    removeBinderModalVisible,
    toggleRemoveBinderModalVisibility,
    binderData,
  } = useBinderContext();
  const { mutateAsync: removeBinder, isLoading: removeProgress } =
    useRemoveBinder({
      onSuccess: () => {
        client.invalidateQueries([QUERIES.BINDERS]);
        toggleRemoveBinderModalVisibility();
        notification.info({
          message: t(`Binder has been removed successfully!`),
        });
      },
    });

  return (
    <Modal
      visible={removeBinderModalVisible}
      onCancel={() => toggleRemoveBinderModalVisibility()}
      destroyOnClose
      okText={t('Remove')}
      title={t('Remove binder')}
      centered
      okButtonProps={{
        danger: false,
        // @ts-expect-error - this is a bug in antd
        type: 'danger',
        loading: removeProgress,
      }}
      onOk={() => removeBinder(binderData!.external_id)}
    >
      <div className="text-center">
        <Typography.Title level={5}>
          {t('Are you sure you want to remove binder?')}
        </Typography.Title>
        <Typography.Text type="secondary" italic>
          {binderData?.name}
        </Typography.Text>
      </div>
    </Modal>
  );
};
