import type { FC } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Form, Modal, Select, Tooltip } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { useDebouncedState } from '@react-hookz/web';

import type { PermissionEnum } from 'src/api-sdk';
import { usePaginatedData } from 'src/hooks/useNormalizePaginatedData';
import { useBinderContext } from 'src/util/context/BinderContext';
import { QUERIES } from 'src/util/globals';
import { useUsers } from 'src/hooks/useUsers';
import { usePermissionsContext } from 'src/util/context/PermissionsContext';
import { usePortfolioContext } from 'src/util/context/PortfolioContext';
import PermissionOptionSelect from 'src/components/Permissions/components/PermissionOptionSelect';

import { useAddUserToBinder } from '../hooks/binders/useAddUserToBinder';
import { useAddUserToPortfolio } from '../hooks/portfolios';

type Props = {
  visible: boolean;
  toggleVisibility: (v?: boolean) => void;
};

type Inputs = { userId: string; permission: PermissionEnum };

export const AddUserToEntity: FC<Props> = ({ visible, toggleVisibility }) => {
  const client = useQueryClient();
  const { t } = useTranslation('permissions');
  const { permissionsModalState } = usePermissionsContext();
  const { portfolioId } = usePortfolioContext();
  const [form] = Form.useForm<Inputs>();
  const [searchQuery, setSearchQuery] = useDebouncedState<string | undefined>(
    undefined,
    300
  );
  const { data, isLoading, isFetching } = useUsers({
    enabled: visible,
    searchQuery,
  });
  const { results: users } = usePaginatedData(data);
  const [selectedUserId, setSelectedUserId] = useState<null | string>(null);
  const { binderData } = useBinderContext();

  const { mutateAsync: addUserToBinder, isLoading: addUserToBinderProgress } =
    useAddUserToBinder({
      onSuccess: () => {
        client.invalidateQueries([QUERIES.BINDER_USERS]);
      },
    });
  const {
    mutateAsync: addUserToPortfolio,
    isLoading: addUserToPortfolioProgress,
  } = useAddUserToPortfolio({
    onSuccess: () => {
      client.invalidateQueries([QUERIES.PORTFOLIO_USERS]);
    },
  });

  const isUpdateInProgress =
    addUserToBinderProgress || addUserToPortfolioProgress;

  const handleSubmit = useCallback(async () => {
    const values = await form.validateFields();

    if (permissionsModalState?.entity === 'binder') {
      await addUserToBinder({
        binderId: binderData!.external_id,
        userId: values.userId,
        permission: values.permission,
      }).then(() => toggleVisibility(false));
    }

    if (permissionsModalState?.entity === 'portfolio') {
      await addUserToPortfolio({
        portfolioId: portfolioId!,
        userId: values.userId,
        permission: values.permission,
      }).then(() => toggleVisibility(false));
    }
  }, [
    addUserToBinder,
    addUserToPortfolio,
    binderData,
    form,
    permissionsModalState?.entity,
    portfolioId,
    toggleVisibility,
  ]);

  return (
    <Modal
      visible={visible}
      onCancel={() => toggleVisibility()}
      title={t(`Add new user to ${permissionsModalState?.entity}`)}
      okText={t('Save')}
      destroyOnClose
      okButtonProps={{ loading: isUpdateInProgress }}
      onOk={handleSubmit}
      afterClose={() => {
        form.resetFields();
        setSelectedUserId(null);
      }}
      width="40%"
      centered
    >
      <Form
        form={form}
        layout="inline"
        className="flex flex-row justify-center"
      >
        <Form.Item
          name="userId"
          label={t('Select user')}
          className="w-3/6"
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t('Select user')}
            className="mb-3"
            onChange={(value: string) => {
              setSelectedUserId(value ?? null);
            }}
            allowClear
            onSearch={setSearchQuery}
            showSearch
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            loading={isFetching || isLoading}
          >
            {users.map(({ user: { email, external_id } }) => (
              <Select.Option key={external_id} value={external_id}>
                {email}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="permission"
          label={t('Permission')}
          className="w-3/6"
          rules={[{ required: true }]}
        >
          <PermissionOptionSelect
            disabled={!selectedUserId}
            entity={permissionsModalState?.entity || ''}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
