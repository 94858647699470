import React, { useContext } from 'react';
import type { AxiosInstance } from 'axios';
import axios from 'axios';
import { useRouter } from 'next/router';

interface AxiosContextInterface {
  api: AxiosInstance;
  baseApiUrl: string;
}

const axiosContext = React.createContext<AxiosContextInterface>(
  {} as ReturnType<typeof useProvideAxios>
);

// Provider component that wraps your app and makes axios object ..
// ... available to any child component that calls useAxios().

export function ProvideAxios({ children }: any): any {
  const auth = useProvideAxios();
  return <axiosContext.Provider value={auth}>{children}</axiosContext.Provider>;
}

// Hook for child components to get the axios object ...
// ... and re-render when it changes.

export const useAxios = () => {
  return useContext(axiosContext);
};

// Provider hook that creates auth object and handles state
function useProvideAxios(): AxiosContextInterface {
  const router = useRouter();
  const baseApiUrl = process.env.NEXT_PUBLIC_API_URL as string;

  const api = axios.create({
    baseURL: baseApiUrl,
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': router.locale as string,
    },
  });
  api.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return {
    api,
    baseApiUrl,
  };
}
