import type { FC } from 'react';
import { useMemo } from 'react';
import type { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'next-i18next';
import { useToggle } from '@react-hookz/web';
import { Button, Popover, Tooltip, Modal } from 'antd';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';

import { usePaginatedData } from 'src/hooks/useNormalizePaginatedData';
import { useBinderContext } from 'src/util/context/BinderContext';
import { SmartTable } from 'src/components/SmartTable';
import { usePermissionsContext } from 'src/util/context/PermissionsContext';
import type {
  BinderProfileUserGroup,
  PortfolioProfileUserGroup,
} from 'src/api-sdk';
import { QUERIES } from 'src/util/globals';
import { usePortfolioContext } from 'src/util/context/PortfolioContext';

import { useBinderGroups, useRemoveGroupFromBinder } from '../hooks/binders';
import { AddGroupToEntity } from './AddGroupToEntity';
import { UpdateGroupPermissions } from './UpdateGroupPermissions';
import { usePortfolioGroups } from '../hooks/portfolios/usePortfolioGroups';
import { useRemoveGroupFromPortfolio } from '../hooks/portfolios';

type TableData = {
  groups: BinderProfileUserGroup[] | PortfolioProfileUserGroup[] | undefined;
  hasMore: boolean | undefined;
  isLoading: boolean | undefined;
  isFetching: boolean | undefined;
  fetchNext: () => void;
} | null;

export const PermissionsGroupsList: FC = () => {
  const [addNewGroupVisible, toggleNewGroupVisible] = useToggle(false);
  const { t } = useTranslation('permissions');
  const { binderData } = useBinderContext();
  const { permissionsModalState } = usePermissionsContext();
  const client = useQueryClient();
  const { portfolioId } = usePortfolioContext();

  const {
    data: binderGroupsData,
    isLoading: isBinderGroupsLoading,
    hasNextPage: binderGroupsHasMore,
    fetchNextPage: binderGroupsFetchNext,
    isFetching: isBinderGroupsFetching,
  } = useBinderGroups(
    {
      binderId: binderData?.external_id,
    },
    {
      enabled: Boolean(
        permissionsModalState?.visible &&
          permissionsModalState.entity === 'binder'
      ),
      cacheTime: 0,
    }
  );

  const {
    data: portfolioGroupsData,
    isLoading: isPortfolioGroupsDataLoading,
    hasNextPage: portfolioGroupsHasMore,
    fetchNextPage: portfolioGroupsFetchNext,
    isFetching: isPortfolioGroupsFetching,
  } = usePortfolioGroups(
    {
      portfolioId: portfolioId!,
    },
    {
      enabled: Boolean(
        permissionsModalState?.visible &&
          permissionsModalState.entity === 'portfolio'
      ),
      cacheTime: 0,
    }
  );

  const { results: binderGroups } = usePaginatedData(binderGroupsData);
  const { results: portfolioGroups } = usePaginatedData(portfolioGroupsData);

  const tableData = useMemo<TableData>(() => {
    if (permissionsModalState?.entity === 'binder') {
      return {
        groups: binderGroups,
        hasMore: binderGroupsHasMore,
        fetchNext: binderGroupsFetchNext,
        isLoading: isBinderGroupsLoading,
        isFetching: isBinderGroupsFetching,
      };
    } else if (permissionsModalState?.entity === 'portfolio') {
      return {
        groups: portfolioGroups,
        hasMore: portfolioGroupsHasMore,
        fetchNext: portfolioGroupsFetchNext,
        isLoading: isPortfolioGroupsDataLoading,
        isFetching: isPortfolioGroupsFetching,
      };
    }

    return null;
  }, [
    permissionsModalState?.entity,
    binderGroups,
    binderGroupsHasMore,
    binderGroupsFetchNext,
    isBinderGroupsLoading,
    isBinderGroupsFetching,
    portfolioGroups,
    portfolioGroupsHasMore,
    portfolioGroupsFetchNext,
    isPortfolioGroupsDataLoading,
    isPortfolioGroupsFetching,
  ]);

  const { mutateAsync: removeGroupFromBinder, isLoading: groupRemoveProgress } =
    useRemoveGroupFromBinder({
      onSuccess: () => {
        client.invalidateQueries([QUERIES.BINDER_GROUPS]);
      },
    });

  const {
    mutateAsync: removeGroupFromPortfolio,
    isLoading: groupRemoveProgressP,
  } = useRemoveGroupFromPortfolio({
    onSuccess: () => {
      client.invalidateQueries([QUERIES.PORTFOLIO_GROUPS]);
    },
  });

  const handleRemoveGroupFromEntity = async (groupId: string) => {
    Modal.confirm({
      title: t(`Remove group from ${permissionsModalState?.entity}?`),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        if (permissionsModalState?.entity === 'binder') {
          await removeGroupFromBinder({
            binderId: binderData!.external_id,
            groupId,
          });
        }

        if (permissionsModalState?.entity === 'portfolio') {
          await removeGroupFromPortfolio({
            portfolioId: portfolioId!,
            groupId,
          });
        }
      },
      okText: t('Confirm'),
      cancelText: t('Cancel'),
      okButtonProps: {
        loading: groupRemoveProgress,
      },
    });
  };

  const columns: ColumnsType<
    BinderProfileUserGroup | PortfolioProfileUserGroup
  > = [
    {
      title: t('Name'),
      dataIndex: ['profile_user_group', 'name'],
    },
    {
      title: t('Permission'),
      dataIndex: 'permission',
      render: (text) => {
        return <span>{t(text.charAt(0).toUpperCase() + text.slice(1))}</span>;
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="flex justify-start items-start">
            <Tooltip title={t('Change permission')}>
              <Popover
                title={t('Select new permission')}
                trigger="click"
                placement="bottom"
                destroyTooltipOnHide
                content={
                  <UpdateGroupPermissions
                    currentPermission={record.permission!}
                    groupId={record.external_id}
                  />
                }
              >
                <Button
                  icon={<LockOutlined />}
                  className="!mb-0"
                  type="dashed"
                />
              </Popover>
            </Tooltip>
            <Tooltip title={t('Remove from binder')}>
              <Button
                icon={<DeleteOutlined />}
                className="!mb-0"
                danger
                onClick={() => handleRemoveGroupFromEntity(record.external_id)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="flex flex-row justify-end">
        <Button onClick={() => toggleNewGroupVisible()}>
          {t('Select user group')}
        </Button>
      </div>
      <SmartTable
        infinity
        dataSource={tableData?.groups ?? []}
        columns={columns}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        fetchNext={tableData?.fetchNext ?? (() => {})}
        hasNext={tableData?.hasMore ?? false}
        loading={(tableData?.isLoading || tableData?.isFetching) ?? false}
      />
      <AddGroupToEntity
        visible={addNewGroupVisible}
        toggleVisibility={toggleNewGroupVisible}
      />
    </div>
  );
};
