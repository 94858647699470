import { Button, Form, notification, Select } from 'antd';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import type { PermissionEnum } from 'src/api-sdk';
import { useBinderContext } from 'src/util/context/BinderContext';
import { QUERIES } from 'src/util/globals';
import { usePermissionsContext } from 'src/util/context/PermissionsContext';
import { usePortfolioContext } from 'src/util/context/PortfolioContext';
import PermissionOptionSelect from 'src/components/Permissions/components/PermissionOptionSelect';

import { useUpdateGroupPermissionForBinder } from '../hooks/binders';
import { useUpdateGroupPermissionForPortfolio } from '../hooks/portfolios';

type Props = {
  groupId: string | null;
  currentPermission: PermissionEnum;
};

type Inputs = {
  permission: PermissionEnum;
};

export const UpdateGroupPermissions: FC<Props> = ({
  groupId,
  currentPermission,
}) => {
  const { binderData } = useBinderContext();
  const { portfolioId } = usePortfolioContext();
  const client = useQueryClient();
  const { t } = useTranslation('permissions');
  const { permissionsModalState } = usePermissionsContext();
  const [form] = Form.useForm<Inputs>();

  const {
    mutateAsync: updateBinderGroupPermission,
    isLoading: updateInProgressB,
  } = useUpdateGroupPermissionForBinder(
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    { binderId: binderData?.external_id!, groupId: groupId! },
    {
      onSuccess: () => {
        client.invalidateQueries([QUERIES.BINDER_GROUPS]);
        notification.success({
          message: t('Permissions updated successfully!'),
        });
      },
    }
  );
  const {
    mutateAsync: updatePortfolioGroupPermission,
    isLoading: updateInProgressP,
  } = useUpdateGroupPermissionForPortfolio(
    { portfolioId: portfolioId!, groupId: groupId! },
    {
      onSuccess: () => {
        client.invalidateQueries([QUERIES.PORTFOLIO_GROUPS]);
        notification.success({
          message: t('Permissions updated successfully!'),
        });
      },
    }
  );

  const onFinish = useCallback(async () => {
    const values = await form.validateFields();

    if (permissionsModalState?.entity === 'binder') {
      await updateBinderGroupPermission(values);
    }

    if (permissionsModalState?.entity === 'portfolio') {
      await updatePortfolioGroupPermission(values);
    }
  }, [
    form,
    permissionsModalState?.entity,
    updateBinderGroupPermission,
    updatePortfolioGroupPermission,
  ]);

  return (
    <Form
      form={form}
      initialValues={{
        permission: currentPermission,
      }}
      layout="vertical"
      className="flex flex-col"
      onFinish={onFinish}
    >
      <Form.Item name="permission" label={t('Permission')} className="!mb-3">
        <PermissionOptionSelect entity={permissionsModalState?.entity || ''} />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={updateInProgressB || updateInProgressP}
      >
        {t('Update')}
      </Button>
    </Form>
  );
};
